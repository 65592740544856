import * as React from "react";
import axios from "axios";
import {appConfig} from "../reducers/appConfig";
import { IUser } from "../interfaces/reducers/IAppStore";
import OverlayLoader from "../Loading";
import { IPopupDispatcher } from "../interfaces/IPopupDispatcher";
import { UserViewModel } from "../interfaces/UserViewMode";
import SetNewPass from "./SetNewPass";
import  EditRoles from "./EditRoles";
import  EditBoundries from "./EditBoundries";
import { RolePicker } from "./RolePicker";

interface Props {
    user: IUser;
    back: () => void;
    popupDispatcher: IPopupDispatcher;
}

interface State {
    loading: boolean;
    pageUsers: Array<UserViewModel>;
    page: number;
    pageSize: number;
    allUsersCount: number;
    searchRoles: {[role: string]: any};
    searchUsername: string;
    searchName: string;
    searchActive?: boolean;
    searchEmail: string;
    Boundries: {[boundryId: string]: any};
}

class UserRegister extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            pageUsers: [],
            page: 0,
            pageSize: 25,
            allUsersCount: 0,
            searchRoles: {},
            searchUsername: "",
            searchName: "",
            searchEmail: "",
            Boundries: {}
        }
    }

    componentWillMount() {
        this.getBoundryNames();
        this.refreshUsers();
    }

    refreshUsers = () => {
        this.setState({...this.state, loading: true})
        axios.post(appConfig.urls.getUsers, {
            Page: this.state.page,
            PageSize: this.state.pageSize,
            Roles: Object.keys(this.state.searchRoles),
            Username:this.state.searchUsername,
            Name: this.state.searchName,
            Actives: this.state.searchActive,
            Email: this.state.searchEmail
        }).then(r => {
            this.setState({ ...this.state, pageUsers: r.data.Users, allUsersCount: parseInt(r.data.Count), loading: false })
        })
        .catch(e => {
            this.setState({ ...this.state, loading: false })
        })
    }

    getBoundryNames = () => {
        axios.get(appConfig.urls.boundry, {
        }).then(r => {
            let Boundries: {[boundryId: string]: any} = {};
            r.data.forEach((b: { Id: any; Name: any; }) => {
                Boundries[b.Id] = b.Name;
            })
            this.setState({...this.state, Boundries})
        })
        .catch(e => {
            console.log(e);
            this.setState({ ...this.state, loading: false })
        })
    }

    toggleActive = (user: UserViewModel) => {
        this.setState({...this.state, loading: true})
        axios.post(appConfig.urls.toggleActiveteUser, {
            Username: user.Username,
            Activate: !user.Active
        }).then(r => {
            this.refreshUsers();
        })
        .catch(e => {
            this.setState({ ...this.state, loading: false })
        })
    }

    getUsersByRole(role: string){
        role = role.toUpperCase();
        let users: Array<JSX.Element> = [];
        this.state.pageUsers.forEach((user, i) => {
            var roles = user.Roles.map(r => r.toUpperCase());
            if(roles.indexOf(role) != -1 || !role){
                users.push(
                    <tr key={i}>
                        <td>
                            {user.Username}
                        </td>
                        <td>
                            {user.Name}
                        </td>
                        <td>
                            {user.Roles.map(r => appConfig.identityStore.roles[r].label).join(", ")}
                        </td>
                        <td>
                            {user.AdminRestrictions.map((a: { BoundryId: any; }) => this.state.Boundries[a.BoundryId]).join(", ") || "Няма"}
                        </td>
                        <td style={{width: "250px"}}>
                            <button className={"app-form-control " + (user.Active ? "btn-danger" : "btn-success")}
                                onClick={() => {
                                    this.toggleActive(user);
                                }}>{user.Active ? "Деактивирай" : "Активирай"}</button>
                            <button className={"app-form-control btn-danger"}
                                onClick={() => {
                                    this.props.popupDispatcher.addPopups({
                                        setNewPass: {
                                            width: "500px",
                                            content: <SetNewPass user={user}/>,
                                            header: <div>Задай нова парола</div>
                                        }
                                    })
                                }}>Задай нова парола</button>
                            <button className={"app-form-control btn-danger"}
                                onClick={() => {
                                    this.props.popupDispatcher.addPopups({
                                        setNewPass: {
                                            width: "500px",
                                            content: <EditRoles user={user}/>,
                                            header: <div>Редактирай роли</div>
                                        }
                                    })
                                }}>Редактирай роли</button>
                                <button className={"app-form-control btn-danger"}
                                onClick={() => {
                                    this.props.popupDispatcher.addPopups({
                                        setNewPass: {
                                            width: "500px",
                                            content: <EditBoundries user={user}/>,
                                            header: <div>Редактирай Зони</div>
                                        }
                                    })
                                }}>Редактирай Зони</button>
                        </td>
                    </tr>
                )
            }
        })
        if(users.length == 0){
            return (
                <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px", padding: "20px",
                marginTop: "10px", border: "1px", backgroundColor: "#ff00002e"}}>Няма потребители</div>
            )
        }
        return (
            <table style={{width: "100%"}} className="table table-striped">

                <tbody>
                    <tr>
                        <th>Потребителско име</th>
                        <th>Име</th>
                        <th>Роли</th>
                        <th>Рестриктивна зона</th>
                        <th>Инструменти</th>
                    </tr>
                    {users}
                    <tr>
                        <td colSpan={6}>
                            {this.getTablePageControl()}
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    getFilterMenu(){
        let filterMenuStyle: React.CSSProperties = {
            display: "flex",
            padding: "5px",
            alignItems: "center"
        }
        let filterMenuLabelStyle: React.CSSProperties = {
            margin: "0 10px"
        }
        let options: { value: any, label: any }[] = [];
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Име</div>
                    <input className="form-control" value={this.state.searchName} onChange={(e: { target: { value: any; }; }) => this.setState({...this.state, searchName: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Потребителско име</div>
                    <input className="form-control" value={this.state.searchUsername} onChange={(e: { target: { value: any; }; }) => this.setState({...this.state, searchUsername: e.target.value})}></input>
                </div>
                <div style={filterMenuStyle}>
                    <div style={filterMenuLabelStyle}>Всички</div>
                    <input checked={this.state.searchActive == null || this.state.searchActive == undefined}
                    type="radio" name="activeonly" style={{transform:"scale(1.6)" }}
                    onClick={() => this.setState({...this.state, searchActive: undefined})}
                    ></input>

                    <div style={filterMenuLabelStyle}>Активни</div>
                    <input checked={this.state.searchActive === true}
                    type="radio" name="activeonly" style={{transform:"scale(1.6)" }}
                    onClick={() => this.setState({...this.state, searchActive: true})}
                    ></input>

                    <div style={filterMenuLabelStyle}>Неактивни</div>
                    <input checked={this.state.searchActive === false}
                    type="radio" name="activeonly" style={{transform:"scale(1.6)" }}
                    onClick={() => this.setState({...this.state, searchActive: false})}
                    ></input>
                </div>
                <div style={{...filterMenuStyle, flexDirection: "column", maxHeight: "50px", minHeight: "50px", padding: "0", margin: "0 15px", minWidth: "300px"}}>
                    <input readOnly={true} value={Object.keys(this.state.searchRoles).join(",")} onChange={() => {}} style={{...filterMenuLabelStyle, height: "25px", width: "100%"}}></input>
                    <button onClick={() => {
                        this.props.popupDispatcher.addPopups({
                            setFIlterRoles: {
                                width: "500px",
                                content: <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                    <RolePicker
                                identityStore={appConfig.identityStore}
                                selectedRoles={this.state.searchRoles}
                                onSelectionChanged={(searchRoles) => this.setState({...this.state, searchRoles})}/>
                                <br></br>
                                <button className="btn btn-dark" onClick={() => this.props.popupDispatcher.removePopups(["setFIlterRoles"])}>ОК</button>
                                </div>,
                                header: <div>Изберете роли за филтриране</div>
                            }
                        })
                    }} style={{height: "25px", width: "100%", padding: "0"}} className="btn btn-dark">Изберете роли</button>
                </div>
                <button style={{height: "40px"}} className="btn btn-dark"
                onClick={() => {
                    this.setState({...this.state, page: 0}, () => {
                        this.refreshUsers();
                    })
                }}>Филтрирай</button>
            </div>
        )
    }

    getPagesNum(){
        return Math.floor(this.state.allUsersCount / this.state.pageSize) + (this.state.allUsersCount % this.state.pageSize == 0 ? 0 : 1 );
    }

    getTablePageControl = () => {
        var isOverflowing = ((this.state.page * this.state.pageSize) + this.state.pageUsers.length > this.state.allUsersCount && this.state.pageUsers.length == this.state.pageSize);
        return (
            <div style={{display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "200px",
                margin: "auto"}}>
                <button
                    disabled={this.state.page == 0}
                    onClick={() => {
                        this.setState({...this.state, page: this.state.page - 1}, () => {
                            this.refreshUsers();
                        })
                    }}
                >{"<"}</button>
                {this.state.page * this.state.pageSize + 1} - {isOverflowing ? this.state.allUsersCount : (this.state.page * this.state.pageSize) + this.state.pageUsers.length}
                <br></br>
                стр: {this.state.page + 1}/{this.getPagesNum()}
                <button
                    disabled={(this.state.page + 1) * this.state.pageSize > this.state.allUsersCount}
                    onClick={() => {
                        this.setState({...this.state, page: this.state.page + 1}, () => {
                            this.refreshUsers();
                        })
                    }}
                >{">"}</button>
            </div>
        )
    }

    register = () => {
        return this.getUsersByRole("");
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", margin: "50px 100px 0 100px" }}>
                <OverlayLoader size="30px" show={this.state.loading} />
                <div style={{display: "flex", alignItems: "center", margin: "15px", justifyContent: "space-around", width: "400px"}}>
                    <button className="btn btn-secondary" onClick={this.props.back}>{"<< Назад"}</button>
                    <div>Регистър на потребителите</div>
                </div>
                {this.getFilterMenu()}
                {this.register()}
            </div>
        )
    }
}

export default UserRegister;
