import * as React from "react";
import { IIdentityStore } from "../reducers/appConfig";
import axios from "axios";
import {appConfig} from "../reducers/appConfig";

interface Props {
    onSelectionChanged: (newSelection: {[role: string]: any}) => void;
    identityStore: IIdentityStore;
    selectedBoundries: {[role: string]: any}
}

interface State {
    advanced: boolean;
    selectedBoundry: {[boundryId: string]: any};
    Boundries: {[boundryId: string]: any};

}

export class BoundryPicker extends React.Component<Props, State> {
    constructor(props: Props){
        super(props);
        this.state = {
            advanced: false,
            selectedBoundry: props.selectedBoundries,
            Boundries: {}
        }
    }



    getBoundryNames = () => {
        axios.get(appConfig.urls.boundry, {
        }).then(r => {
            let Boundries: {[boundryId: string]: any} = {};
            r.data.forEach((b: { Id: any; Name: any; }) => {
                Boundries[b.Id] = b.Name;
            })
            this.setState({...this.state, Boundries})
        })
        .catch(e => {
            console.log(e);
        })
    }

    componentWillMount() {
        this.getBoundryNames();
    }

    componentWillReceiveProps(props: Props){
        this.setState({...this.state, selectedBoundry: props.selectedBoundries})
    }

    hasGroups(): boolean {
        let has = false;
        for(let g in this.props.identityStore.roles){
            if(Object.keys(this.props.identityStore.roles[g].groups).length > 0){
                has = true;
                break
            }
        }
        return has;
    }

    getGroupedRoleSelector(){}

    getAdvancedRoleSelector(){
        let checkboxes: JSX.Element[] = [];
        let selectedBoundries = {...this.state.selectedBoundry};
        for (let role in this.state.Boundries) {
            checkboxes.push(
                <div key={role} style={{ display: "flex", alignItems: "center"}}>
                    <input
                        type="checkbox"
                        style={{ transform: "scale(0.8)", marginRight: "1px" }}
                        checked={!!selectedBoundries[role]}
                        onChange={() => {
                            if (selectedBoundries[role]) {
                                delete selectedBoundries[role]
                            }
                            else {
                                selectedBoundries[role] = {}
                            }
                            this.setState({...this.state, selectedBoundry: selectedBoundries}, () => {
                                this.props.onSelectionChanged(selectedBoundries)
                            })
                        }}></input>
                    <div>{this.state.Boundries[role]}</div>
                </div>
            )
        }
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h5>Изберете Зони:</h5>
                {checkboxes}
            </div>
        );
    }

    getRoleSelector(hasGroups: boolean){
        return (!hasGroups || this.state.advanced) ? this.getAdvancedRoleSelector() : this.getGroupedRoleSelector();
    }

    getAdvancedToggler(){
        return (
            <button onClick={() => this.setState({...this.state, advanced: !this.state.advanced})}>
                {this.state.advanced ? "Излез от разширеното търсене" : "Разширено търсене"}
            </button>
        )
    }
    render(){
        let hasGroups = this.hasGroups();
        return(
            <div>
                {hasGroups ? this.getAdvancedToggler() : null}
                {this.getRoleSelector(hasGroups)}
            </div>
        )
    }
}
