var search = location.search.substring(1);
var queryObject = {} as {[key: string]: string};
if(search){
    queryObject = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}
export interface IIdentityStore {
    roles: {
        [key: string]: {
            label: string,
            groups: {[group: string]: boolean}
        }
    },
    groupLabels: {[group: string]: string}
};
declare global {
    interface Window {
        setCookie: (name: string, value: string, days?: number) => void;
        getCookie: (name: string) => string;
        eraseCookie: (name: string, paths: Array<string>) => void;
        apiUrl: string;
        identityStore: IIdentityStore;
    }
};

const appConfig: IAppConfig = {
    register: !!queryObject["register"],
    apiUrl: window.apiUrl,
    identityStore: window.identityStore,
    urls: {
        tokenUrl: window.apiUrl + "Account/token",
        changePassword: window.apiUrl + "Account/ChangePassword",
        registerUser: window.apiUrl + "AdminAccount/registerUser",
        setRoles: window.apiUrl + "AdminAccount/setRoles",
        canRegister: window.apiUrl + "AdminAccount/canRegister",
        registerAdmin: window.apiUrl + "AdminAccount/registerAdmin",
        setUserPassword: window.apiUrl + "AdminAccount/setUserPassword",
        getUsers: window.apiUrl + "AdminAccount/getUsers",
        toggleActiveteUser: window.apiUrl + "AdminAccount/toggleActiveteUser",
        queryLogs: window.apiUrl + "AdminAccount/queryLogs",
        boundry: window.apiUrl + "AdminAccount/getBoundry",
        setBoundries: window.apiUrl + "AdminAccount/setAdminRestriction"
    }
};

interface IAppConfig {
    identityStore: IIdentityStore;
    register: boolean;
    apiUrl: string;
    urls: {
        tokenUrl: string;
        changePassword: string;
        registerUser: string;
        setRoles: string;
        canRegister: string;
        registerAdmin: string;
        setUserPassword: string;
        getUsers: string;
        toggleActiveteUser: string;
        queryLogs: string;
        boundry: string;
        setBoundries: string;
    }
};

export { appConfig };
